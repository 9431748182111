import React, { useEffect, useRef, useState } from "react";
import "./shop.scss";

const LazyImage = ({ imageName, getCurrentImageUrl, onImageLoad }) => {
  const [loaded, setLoaded] = useState(false);
  const imgRef = useRef(null);

  useEffect(() => {
    if (imgRef.current) {
      const observer = new IntersectionObserver((entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            const imageUrl = getCurrentImageUrl(imageName);
            if (imageUrl) {
              setLoaded(true);
              onImageLoad();
            }
            observer.disconnect();
          }
        });
      });

      observer.observe(imgRef.current);

      return () => {
        observer.disconnect();
      };
    }
  }, [imageName, getCurrentImageUrl, onImageLoad]);
  if (loaded) {
    return (
      <img
        src={getCurrentImageUrl(imageName)}
        alt=""
        className="product-cover"
      />
    );
  } else {
    return <div ref={imgRef} className="image-placeholder"></div>;
  }
};
export default LazyImage;
