import React, { useState } from "react";
import "./navbar.scss";
import { Link } from "react-router-dom";
import logo from "../../assests/logo/logo nondit edit png.png";
import {
  AiOutlineShoppingCart,
  AiOutlineInstagram,
  AiOutlineClose,
} from "react-icons/ai";
import { HiBars2 } from "react-icons/hi2";
import MobileNavbar from "./mobileNavbar";
import { navbarData } from "../../constant/data/navbarData";
import { useCartContext } from "../../context/cartContext";
import { useLocation } from "react-router-dom/dist";

const Navbar = () => {
  const { pathname } = useLocation();
  const [isToggle, setIsToggle] = useState(true);
  const { totalItem } = useCartContext();

  React.useEffect(() => {
    if (!isToggle) {
      document.body.classList.add("stop-scrolling");
    } else {
      document.body.classList.remove("stop-scrolling");
    }

    return () => {
      document.body.classList.remove("stop-scrolling");
    };
  }, [isToggle]);

  return (
    <>
      <nav className="container flex justify-between items-center">
        <div
          className="bars flex items-center text-3xl"
          onClick={() => {
            setIsToggle(!isToggle);
          }}
        >
          {isToggle ? (
            <HiBars2 className="cursor-pointer" />
          ) : (
            <AiOutlineClose className="cursor-pointer" />
          )}
        </div>
        <div>
          <Link to="/">
            <img src={logo} alt="" className="md:w-72 w-40"></img>
          </Link>
        </div>
        <div className="flex text-2xl items-center">
          <Link to="https://www.instagram.com/thenonditedit_/" target="_blank">
            {" "}
            <AiOutlineInstagram className="mr-3 md:block hidden cursor-pointer" />
          </Link>

          <Link to="/cart" className="flex">
            <AiOutlineShoppingCart className="mr-1 cursor-pointer" />
            <p className="text-base">{totalItem}</p>
          </Link>
        </div>
        <MobileNavbar
          isToggle={isToggle}
          setIsToggle={setIsToggle}
          navbarData={navbarData}
          pathname={pathname}
        />
      </nav>
    </>
  );
};

export default Navbar;
