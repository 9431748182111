import React, { useEffect, useState } from "react";
import "./productImages.scss";
import Loader from "../loader/loader";
import { AiOutlineArrowLeft, AiOutlineArrowRight } from "react-icons/ai";
import { initializeApp } from "firebase/app";
import { getStorage, ref, getDownloadURL } from "firebase/storage";
import { SideBySideMagnifier } from "react-image-magnifiers-v2";

const firebaseConfig = {
  apiKey: "AIzaSyA3EKM4mOUuWE74-xaKJeDuzox0ekplrjA",
  authDomain: "the-non-dit.firebaseapp.com",
  projectId: "the-non-dit",
  storageBucket: "the-non-dit.appspot.com",
  messagingSenderId: "1017386525662",
  appId: "1:1017386525662:web:0026383ca5d250460db34f",
  measurementId: "G-7MCKH424RF",
};
const app = initializeApp(firebaseConfig);
const storage = getStorage(app);
const imageFetchingLogic = async (imageName) => {
  // console.log("we are inside imagefetching logic")
  const fileRef = ref(storage, imageName);
  const url = await getDownloadURL(fileRef);
  // console.log("url before returning", url)
  return url;
};

const fetchAllImageUrls = async (imageNames) => {
  const allUrls = {};

  if (imageNames) {
    for (const imageName of imageNames) {
      const url = await imageFetchingLogic(imageName);
      allUrls[imageName] = url;
    }
  }

  return allUrls;
};

const ProductModalImages = ({ images }) => {
  const [imageUrls, setImageUrls] = useState({});
  useEffect(() => {
    async function loadAllUrls() {
      const urls = await fetchAllImageUrls(images);
      setImageUrls(urls);
    }

    loadAllUrls();
  }, [images]);

  const getCurrentImageUrl = (imageName) => {
    return imageUrls[imageName];
  };

  const [curImageIndex, setCurImageIndex] = useState(0);

  if (!images) {
    return <Loader />;
  }

  const nextImage = () => {
    setCurImageIndex((curImageIndex + 1) % images.length);
  };

  const prevImage = () => {
    setCurImageIndex(
      curImageIndex === 0 ? images.length - 1 : curImageIndex - 1
    );
  };

  return (
    <>
      <section className="image-section flex gap-5 ">
        <div className="mobile-images sm:flex flex-col gap-5 hidden">
          {images.map((img, index) => {
            return (
              <img
                src={getCurrentImageUrl(img)}
                className={`product-img ${
                  index === curImageIndex ? "active" : ""
                }`}
                alt="product"
                key={index}
                onClick={() => {
                  setCurImageIndex(index);
                }}
              ></img>
            );
          })}
        </div>
        <div>
          <div className="carousel-controls flex">
            <button onClick={prevImage} className="text-2xl carousel-btn-prev">
              <AiOutlineArrowLeft />
            </button>
            {/* <img
              src={getCurrentImageUrl(images[curImageIndex])}
              className="main-product-img"
              alt="product"
            ></img> */}
            <div className="flex">
              {getCurrentImageUrl(images[curImageIndex]) && (
                <SideBySideMagnifier
                  className="input-position"
                  imageSrc={getCurrentImageUrl(images[curImageIndex])}
                  largeImageSrc={getCurrentImageUrl(images[curImageIndex])}
                  fillAvailableSpace={false}
                  zoomContainerBorder="1px solid #ccc"
                  zoomContainerBoxShadow="0 4px 8px rgba(0,0,0,.5)"
                />
              )}
            </div>

            <button onClick={nextImage} className="text-2xl carousel-btn-next">
              <AiOutlineArrowRight />
            </button>
          </div>
        </div>
      </section>
    </>
  );
};

export default ProductModalImages;
