import React, { useEffect, useState } from "react";
import "./cart.scss";
import { useCartContext } from "../../context/cartContext";
import { Link } from "react-router-dom";
import Loader from "../../component/loader/loader";
import { initializeApp } from "firebase/app";
import { getStorage, ref, getDownloadURL } from "firebase/storage";

const firebaseConfig = {
  apiKey: "AIzaSyA3EKM4mOUuWE74-xaKJeDuzox0ekplrjA",
  authDomain: "the-non-dit.firebaseapp.com",
  projectId: "the-non-dit",
  storageBucket: "the-non-dit.appspot.com",
  messagingSenderId: "1017386525662",
  appId: "1:1017386525662:web:0026383ca5d250460db34f",
  measurementId: "G-7MCKH424RF",
};
const app = initializeApp(firebaseConfig);
const storage = getStorage(app);
const imageFetchingLogic = async (imageName) => {
  // console.log("we are inside imagefetching logic")
  const fileRef = ref(storage, imageName);
  const url = await getDownloadURL(fileRef);
  // console.log("url before returning", url)
  return url;
};
const fetchAllImageUrls = async (imageNames) => {
  const allUrls = {};

  for (const imageName of imageNames) {
    const url = await imageFetchingLogic(imageName);
    allUrls[imageName] = url;
  }
  return allUrls;
};

const Cart = () => {
  const { cart, removeItem, totalAmount, decreaseAmount, increaseAmount } =
    useCartContext();
  const [isLoading, setIsLoading] = useState(true);
  setTimeout(() => {
    setIsLoading(false);
  }, 2000);

  const [imageUrls, setImageUrls] = useState({});
  const [images, setImages] = useState([]);

  useEffect(() => {
    async function loadAllUrls() {
      for (const c of cart) {
        setImages((prevImages) => [...prevImages, c.image]);
      }
    }

    loadAllUrls();
    //eslint-disable-next-line
  }, []);
  useEffect(() => {
    async function loadAllUrls() {
      const urls = await fetchAllImageUrls(images);
      setImageUrls(urls);
    }

    loadAllUrls();
  }, [images]);

  const getCurrentImageUrl = (imageName) => {
    return imageUrls[imageName];
  };

  return (
    <section className="container">
      {isLoading ? (
        <Loader />
      ) : (
        <div className="md:m-10 m-3">
          <h2 className="font-medium text-2xl mb-5">Shopping Cart</h2>
          {cart.length === 0 ? (
            <div className="flex flex-col gap-3 items-start">
              <p>You have nothing in your shopping cart.</p>
              <Link to="/shop">
                <button className="cart-shopping-btn">Continue Shopping</button>
              </Link>
            </div>
          ) : (
            <div>
              {cart.map((curElem, index) => {
                return (
                  <div key={curElem.id + index}>
                    <div className="flex sm:flex-row flexCol w-full my-5 gap-4 justify-center items-center">
                      <div className="flex gap-3 flex-1 items-center">
                        {/* <img src={` https://sharedclients.ocpl.tech/theNonDitEdit/${curElem.image}`} alt="" className="w-32"></img> */}
                        <img
                          src={getCurrentImageUrl(curElem.image)}
                          alt=""
                          className="w-32"
                        ></img>
                        {/* <ProductImage imageName={curElem.image} /> */}
                        <div>
                          <p className="text-xl font-medium">{curElem.name}</p>
                          <p>{`Size: ${curElem.size}`}</p>
                        </div>
                      </div>
                      <div className="flex flex-1 items-center gap-5 text-xl">
                        <button
                          className="cart-btn"
                          onClick={() =>
                            decreaseAmount(
                              curElem.id,
                              curElem.quantity,
                              curElem.size
                            )
                          }
                        >
                          -
                        </button>
                        <p>{curElem.quantity}</p>
                        <button
                          className="cart-btn"
                          onClick={() =>
                            increaseAmount(
                              curElem.id,
                              curElem.quantity,
                              curElem.size
                            )
                          }
                        >
                          +
                        </button>
                      </div>
                      <div className="flex items-center gap-5">
                        <p>{`₹${curElem.quantity * curElem.price}`}</p>
                        <button
                          className="cart-btn"
                          onClick={() => removeItem(curElem.id, curElem.size)}
                        >
                          X
                        </button>
                      </div>
                    </div>
                    <hr />
                  </div>
                );
              })}
              <div className="flex mt-8">
                <div className="md:flex-grow"></div>
                <div className="flex flex-col gap-3 md:w-1/2 w-full">
                  <div className="flex justify-between text-xl font-semibold">
                    <p>SubTotal</p>
                    <p>{`₹${totalAmount}`}</p>
                  </div>
                  <Link to="/checkout" className="text-end">
                    <button className="cart-shopping-btn">Checkout</button>
                  </Link>
                </div>
              </div>
            </div>
          )}
        </div>
      )}
    </section>
  );
};

export default Cart;
