import React, { useState } from "react";
import { Link, useParams } from "react-router-dom";
import ProductInfo from "../../component/productInfo/productInfo";
import ProductImages from "../../component/productImages/productImages";
import axios from "axios";

const SingleProduct = () => {
  const { productId } = useParams();
  const [products, setProducts] = useState([]);

  React.useEffect(() => {
    axios
      .get(` https://sharedclients.ocpl.tech/api/theNonDitEdit/getProducts`)
      .then((response) => {
        setProducts(response.data);
        // console.log(response.data)
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  }, []);
  const productDetails = products.filter(
    (product) => product.productId === productId
  );
  // console.log(productDetails)
  return (
    <section className="container">
      <div className="single-header">
        <Link to="/shop">Shop&nbsp;</Link>
        <span>
          &nbsp;{">"}&nbsp;{productDetails[0]?.name}
        </span>
      </div>
      <div className="body flex p-5 gap-5 md:flex-row flex-col">
        <div className=" flex product-image flex-1">
          <ProductImages images={productDetails[0]?.images} />
        </div>
        <div className="product-info flex-1">
          <ProductInfo productInfo={productDetails[0]} />
        </div>
      </div>
    </section>
  );
};

export default SingleProduct;
