import React, { useEffect, useState } from "react";
import "./shop.scss";
import { shopSidebar } from "../../constant/data/shopSidebar";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import { initializeApp } from "firebase/app";
import { getStorage, ref, getDownloadURL } from "firebase/storage";
import LazyImage from "./lazyImage";

const firebaseConfig = {
  apiKey: "AIzaSyA3EKM4mOUuWE74-xaKJeDuzox0ekplrjA",
  authDomain: "the-non-dit.firebaseapp.com",
  projectId: "the-non-dit",
  storageBucket: "the-non-dit.appspot.com",
  messagingSenderId: "1017386525662",
  appId: "1:1017386525662:web:0026383ca5d250460db34f",
  measurementId: "G-7MCKH424RF",
};
const app = initializeApp(firebaseConfig);
const storage = getStorage(app);
const imageFetchingLogic = async (imageName) => {
  // console.log("we are inside imagefetching logic")
  const fileRef = ref(storage, imageName);
  const url = await getDownloadURL(fileRef);
  // console.log("url before returning", url)
  return url;
};
const Shop = () => {
  const [isActive, setIsActive] = useState(1);
  const navigate = useNavigate();
  const [products, setProducts] = useState([]);
  const [imageUrls, setImageUrls] = useState({});
  const [images, setImages] = useState([]);
  const [loadedImages, setLoadedImages] = useState({});
  const fetchAllImageUrls = async (imageNames) => {
    const allUrls = {};

    for (const imageName of imageNames) {
      const url = await imageFetchingLogic(imageName);
      allUrls[imageName] = url;
      setImageUrls((prevUrls) => ({ ...prevUrls, [imageName]: url }));
    }

    return allUrls;
  };

  useEffect(() => {
    async function loadAllUrls() {
      const array = [];

      for (const p of products) {
        for (const i of p.images) array.push(i);
        setImages(array);
      }
    }

    loadAllUrls();
  }, [products]);
  useEffect(() => {
    async function loadAllUrls() {
      // console.log("imagesin 2nd", images)
      const urls = await fetchAllImageUrls(images);
      setImageUrls(urls);
    }

    loadAllUrls();
  }, [images]);

  const getCurrentImageUrl = (imageName) => {
    return imageUrls[imageName];
  };

  React.useEffect(() => {
    axios
      .get(` https://sharedclients.ocpl.tech/api/theNonDitEdit/getProducts`)
      .then((response) => {
        setProducts(response.data);
        // console.log(response.data);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  }, []);
  // if (!images) {
  //   return <Loader />;
  // }

  return (
    <main className="container">
      <h2 className="md:text-4xl text-2xl text-center mb-10 font-semibold">
        Shop
      </h2>
      <hr />
      <section className="products  block mt-10">
        <section className="right">
          <ul className="sm:flex hidden sm:flex-col flex-row justify-between gap-3">
            {shopSidebar.map(({ id, navLink }) => (
              <li
                className="m-3 text-xl"
                key={id}
                onClick={() => setIsActive(id)}
              >
                <Link to="/shop" className={id === isActive ? "font-bold" : ""}>
                  {navLink}
                </Link>
              </li>
            ))}
          </ul>
        </section>
        <section className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-6 left">
          {products?.map((product) => {
            const isImageLoaded = loadedImages[product?.images[0]];
            return (
              <div key={product?.productId} className="product-container">
                <div className="image-container">
                  <LazyImage
                    imageName={product?.images[0]}
                    getCurrentImageUrl={getCurrentImageUrl}
                    onImageLoad={() =>
                      setLoadedImages((prev) => ({
                        ...prev,
                        [product?.images[0]]: true,
                      }))
                    }
                  />
                </div>
                {isImageLoaded && (
                  <>
                    <div className="text-center">
                      <p className=" mt-4 ">{product?.name}</p>
                      <small>{`₹${product?.variantDetails[0]?.price}`}</small>
                    </div>
                    <button
                      className="hover-button"
                      onClick={() => {
                        navigate(`/product/${product?.productId}`);
                      }}
                    >
                      quick view
                    </button>
                  </>
                )}
              </div>
            );
          })}
        </section>
      </section>
    </main>
  );
};

export default Shop;
