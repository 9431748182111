import Img1 from "../../assests/carousel/1st.jpg";
import Img2 from "../../assests/carousel/2nd.jpg";
import Img3 from "../../assests/carousel/3rd.jpg";
import Img4 from "../../assests/carousel/5th.jpg";
import Img5 from "../../assests/carousel/6th.jpg";
import Img6 from "../../assests/carousel/7th.jpg";
import Img7 from "../../assests/carousel/8th.jpg";

export const carouselPictures = [
  {
    id: 1,
    img: Img1,
  },
  {
    id: 2,
    img: Img2,
  },
  {
    id: 3,
    img: Img3,
  },
  // {
  //   id: 4,
  //   img: Img4,
  // },
  {
    id: 4,
    img: Img4,
  },
  {
    id: 5,
    img: Img5,
  },
  {
    id: 6,
    img: Img6,
  },
  {
    id: 7,
    img: Img7,
  },
];
