import React from "react";
import "./footer.scss";
import { AiOutlineInstagram } from "react-icons/ai";
import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <footer className="container flex flex-col items-center justify-center gap-8 my-10">
      <section>
        <Link to="https://www.instagram.com/thenonditedit_/" target="_blank">
          <AiOutlineInstagram className="text-3xl cursor-pointer" />
        </Link>
      </section>
      <section>
        <h5>Sign up to receive news and updates.</h5>
      </section>
      <section className="flex flex-wrap items-center justify-center gap-3">
        <input placeholder="Email Address" type="text"></input>
        <button className="footer-btn">Sign Up</button>
      </section>
      <div className="flex flex-col items-center">
        <Link to="https://ocpl.tech" className="font-extrabold" target="_blank">
          <img
            src="https://ocpl.tech/assets/ocpl-ee7a6071.webp"
            className="w-12"
            alt="OCPL LOGO"
          />
        </Link>
        <p>
          Managed and Created by{" "}
          <Link
            to="https://ocpl.tech"
            className="font-extrabold"
            target="_blank"
          >
            Ocpl Tech
          </Link>
        </p>
      </div>
    </footer>
  );
};

export default Footer;
