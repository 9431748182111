import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { carouselPictures } from "../../constant/data/carouselPictures";
import './home.scss';

const Home = () => {
  const settings = {
    arrows: true,
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    fade: true
  };
  return (
    <main className="container">
      <Slider {...settings} className="imgslider">
        {carouselPictures?.map(({img,id}) => {
          return <img src={img} alt="" key={id} />;
        })}
      </Slider>
    </main>
  );
};

export default Home;
